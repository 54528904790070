<template>
  <div v-if="zoneList != null">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          placeholder="Zone.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col class="mt-n13 mt-sm-0">
        <ExportZone :zoneList="zoneList" />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="zoneList"
      class="elevation-1"
      :mobile-breakpoint="0"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.areaDetails`]="{ item }">
        <!-- {{item.areaDetails[0].area.nameEn}} -->
        <div v-for="(areaItem, index) in item.areaDetails" :key="index">
          <span v-if="areaItem.area"> {{ areaItem.area.nameEn }}</span>
          <span v-if="areaItem.block">
            <span v-if="areaItem.block.length > 0">
              ({{ areaItem.block.map((x) => x.block).join(", ") }})
            </span>
          </span>
        </div>
      </template>

      <!-- <template v-slot:item.areaDetails="{ item }"> {{ item.areaDetails.block.block}} </template> -->
      <template v-slot:[`item.active`]="{ item }">
        <!-- <v-checkbox color="btncolor" disabled-color="btncolor" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.active" color="btncolor">mdi-checkbox-marked</v-icon>
        <v-icon v-show="!item.active" color="grey">mdi-checkbox-blank-outline</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <ZoneUpdateForm
            v-if="hasRole(AdminUserPermissions.LOCATION_EDIT)"
            :zone="item"
          />
          <ZoneDeleteForm
            v-if="hasRole(AdminUserPermissions.LOCATION_DELETE)"
            :zone="item"
          />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ZoneUpdateForm from "../components/ZoneUpdateForm";
import ZoneDeleteForm from "../components/ZoneDeleteForm";
import ExportZone from "../components/Export/ExportZone.vue";
export default {
  components: {
    ZoneUpdateForm,
    ZoneDeleteForm,
    ExportZone,
  },
  mounted() {
    this.$root.$on("updateZone", () => {
      this.getAllZones();
    });
  },

  data: () => ({
    search: "",
    dialog: false,
    zoneList: [],
    itemList: [],
    loading: true,
    headers: [
      {
        text: "Zone",
        value: "zoneName",
      },
      { text: "Area", value: "areaDetails" },
      // { text: "Block", value:" "},
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.getAllZones();
  },
  methods: {
    getAllZones() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllZones.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            searchKey: this.search,
          },
        })
        .refetch({
          variables: {
            limit: 0,
            skip: 0,
            searchKey: "",
          },
        })
        .then((data) => {
          this.loading = false;
          if (data.data.getAllZones) {
            this.zoneList = data.data.getAllZones.zones;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    runSearch() {
      this.getAllZones();
    },
    clearSearch() {
      this.search = "";
      this.getAllZones();
    },
  },
  computed: {
    myItemsTransformed() {
      return this.zoneList.map((item) => ({ item }));
    },
  },
};
</script>
