<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="black--text" @click="onSalesOrderEdit"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-3" v-if="salesOrder.package" color="pop_bg">
        <v-row>
          <v-card-title class="text-h5 black--text mt-n2">Diet Plan</v-card-title>
          <v-spacer></v-spacer>

          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="end">
          <!-- <SwitchPlan :subscribedPackage="salesOrder" /> -->
        </v-row>

        <!-- {{ customer_id }} -->
        <!-- {{ user }} -->
        <!-- {{ salesOrder.package.dayChoices }} -->
        <v-divider class="mt-n2 mb-6"></v-divider>
        <!-- <v-container> -->
        <!-- <h3 class="primary--text">Enter Meal Details</h3> -->
        <v-form
          class="pa-4 pa-sm-8 mt-n8"
          @submit="updateSalesorder()"
          v-model="valid"
          ref="form"
        >
          <v-layout wrap>
            <v-flex xs12 sm3 md2>
              <label class="black--text text-body-2">Available Days</label>
            </v-flex>
            <!-- <v-flex xs6 md1> </v-flex> -->
            <v-flex xs12 sm9 md10>
              <v-select
                v-model="availableDays"
                :disabled="isAllowCustomerWeekDays(salesOrder.package)"
                :items="listOfDays"
                item-text="day"
                item-value="day"
                attach
                return-object
                solo
                @change="recalculateEndDate"
                chips
                :rules="[requiredValidator('available days')]"
                label="Available Days"
                multiple
              ></v-select>
            </v-flex>
          </v-layout>
          <v-divider class="mb-6"></v-divider>

          <v-layout wrap>
            <v-flex xs5 sm2 md2>
              <label class="black--text text-body-2">Start date</label>
            </v-flex>
            <v-flex xs6 sm3 md2 class="mt-md-0">
              <!-- <b>
                {{
                  new Date(parseInt(salesOrder.start_date))
                    | moment("MMM DD YYYY")
                }}
              </b> -->

              <v-menu
                v-model="menu1"
                attach
                :close-on-content-click="true"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    readonly
                    :value="fromDate"
                    @change="recalculateEndDate"
                    label="From"
                    solo
                    append-icon="fa-calendar-alt black--text"
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="secondary"
                  v-model="fDate"
                  :min="nowDate"
                  @change="recalculateEndDate"
                >
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs5 sm2 md2 class="ms-sm-16">
              <label class="text-body-2 black--text">End date</label>
            </v-flex>
            <v-flex xs6 sm3 md4 class="mt-md-0">
              <span v-if="endDateLoading">
                <v-progress-circular indeterminate color="btncolor"></v-progress-circular>
              </span>
              <label class="font-weight-bold text-body-2" v-else>
                {{ endDate | moment("MMM DD YYYY") }}</label
              >
            </v-flex>

            <v-flex xs5 sm2 md2 class="mt-3 mt-sm-0">
              <label class="text-body-2 black--text">Protien</label>
            </v-flex>
            <v-flex xs6 sm3 md2 class="mt-3 mt-sm-0">
              <v-text-field
                :rules="[requiredValidator('protien content')]"
                solo
                v-model="salesOrder.package.meanInfo.protein"
                @keypress="isNumber"
                @change="reCalculateTotalPrice"
                type="number"
                min="0"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex xs5 sm2 md2 class="ms-sm-16">
              <label class="text-body-2 black--text">Carb</label>
            </v-flex>
            <v-flex xs6 sm3 md2>
              <v-text-field
                @change="reCalculateTotalPrice"
                v-model="salesOrder.package.meanInfo.carbs"
                @keypress="isNumber"
                :rules="[requiredValidator('carbs content')]"
                solo
                type="number"
                min="0"
                dense
              >
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout wrap>
            <v-flex xs5 sm2 md2 class="mt-3 mt-sm-0">
              <label class="text-body-2 black--text">Subscribed Days</label>
            </v-flex>
            <v-flex xs6 sm3 md2>
              <h4 class="text-body-2">
                <v-text-field
                  :value="getSubscribedDays()"
                  solo
                  disabled
                  dense
                ></v-text-field>
              </h4>
            </v-flex>

            <v-flex xs5 sm2 md2 class="ms-sm-16">
              <h4 class="text-body-2 black--text">Added Days</h4>
            </v-flex>
            <v-flex xs6 sm3 md2>
              <h4 class="text-body-2">
                <v-text-field
                  v-model="additionalDaysIncluded"
                  @keypress="isNumber"
                  :disabled="true"
                  solo
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </h4>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs5 sm2 md2 class="mt-3 mt-sm-0">
              <h4 class="text-body-2 black--text">Additional Days</h4>
            </v-flex>
            <v-flex xs6 sm3 md2>
              <v-text-field
                v-model="additionalDays"
                :disabled="additionalDaysDisabled"
                @keypress="isNumber"
                @change="recalculateEndDate"
                :error-messages="additionalDaysDisabled ? 'Cannot edit end date' : ''"
                solo
                type="number"
                min="0"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex xs5 sm2 md2 class="ms-sm-16">
              <label class="text-body-2 black--text">Available Days</label>
            </v-flex>
            <v-flex xs6 sm3 md2>
              <h4 class="text-body-2">
                <v-text-field
                  v-model="totalAvailableDays"
                  solo
                  disabled
                  dense
                ></v-text-field>
              </h4>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs5 sm2 md2>
              <label class="text-body-2 black--text">Additional Price</label>
            </v-flex>
            <v-flex xs6 sm3 md2 class="mt-md-0 ml-1">
              <h4 class="text-body-2">
                <v-text-field
                  v-model="additionalPrice"
                  @keypress="isFloatNumber"
                  @change="reCalculateTotalPrice"
                  solo
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </h4>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="showPaymentMethod">
            <v-flex xs5 sm2 md2>
              <label class="text-body-2 black--text">Payment Method</label>
            </v-flex>
            <v-flex xs6 md2>
              <v-select
                solo
                dense
                :rules="[requiredValidatorSelect('payment method')]"
                attach
                @change="onPaymentMethodChange"
                item-text="text"
                item-value="value"
                v-model="paymentMethod"
                :items="avalilablePaymentMethod"
              >
              </v-select>
            </v-flex>
          </v-layout>

          <v-divider class="mb-6"></v-divider>
          <v-layout wrap>
            <v-flex xs5 sm2 md2>
              <label class="text-body-2 black--text">Price (KD)</label>
            </v-flex>
            <v-flex xs6 sm3 md2 class="mt-md-0">
              <label class="text-body-2 black--text font-weight-bold">
                {{ salesOrder.package_price.toFixed(3) }}
              </label>
            </v-flex>
            <v-flex xs5 sm2 md2 class="ms-sm-16 mt-3 mt-sm-0">
              <label class="text-body-2 black--text">Discount (KD)</label>
            </v-flex>
            <v-flex xs6 sm3 md2 class="mt-3 mt-sm-0">
              <v-text-field
                v-model="discount"
                label="Discount"
                @keypress="isFloatNumber"
                @change="reCalculateTotalPrice"
                solo
                type="number"
                min="0"
                dense
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs5 sm2 md2 lg2>
              <h4 class="text-body-2">Wallet Applied(KD)</h4>
            </v-flex>
            <v-flex xs6 sm3 md2 class="mt-md-0">
              <h4 class="font-weight-bold">
                {{ salesOrder.walletApplied ? salesOrder.walletApplied.toFixed(3) : "" }}
              </h4>
            </v-flex>
          </v-layout>
          <v-layout wrap class="mt-2">
            <v-flex xs5 sm2 md2 lg2>
              <h4 class="text-body-2">Total(KD)</h4>
            </v-flex>
            <v-flex xs6 sm3 md2>
              <h4 class="font-weight-bold">{{ totalPrice }}</h4>
            </v-flex>

            <v-flex xs5 sm2 md2 lg2 v-if="parseFloat(payableAmount) > 0" class="ms-sm-16">
              <h4 class="text-body-2">Payable(KD)</h4>
            </v-flex>
            <v-flex xs6 sm3 md2 v-if="parseFloat(payableAmount) > 0">
              <label class="text-body-2 font-weight-bold" style="color: red">
                {{ payableAmount }}
              </label>
            </v-flex>
          </v-layout>
          <v-divider class="mt-4 mb-6"></v-divider>
          <v-tabs class="mt-2" v-model="tab" center-active slider-color="black">
            <v-tab v-for="day in availableDays" :key="day.day" :value="day.day"
              ><label class="text-caption text-sm-body-2 black--text text-capitalize">{{
                day.day
              }}</label></v-tab
            >
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(day, index) in availableDays" :key="index">
              <v-card color="pop_bg">
                <span v-show="false">{{ index }}</span>
                <v-flex
                  xs8
                  sm6
                  class="text-body-2 black--text ms-sm-5"
                  v-for="(item, dayIndex) in day.allCategories"
                  :key="item._id"
                >
                  <!-- {{ item}} -->
                  {{ item.nameEn }}
                  <span v-show="false">{{ dayIndex }}</span>
                  <div>
                    <v-layout class="mt-2" wrap row justify-space-around>
                      <v-flex xs4 sm6>
                        <v-text-field
                          :label="item.nameEn + '(No.)'"
                          solo
                          type="number"
                          @keypress="isNumber"
                          v-model="item.count"
                          min="0"
                          dense
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs3 sm4 class="mt-1">
                        <v-btn small color="primary " @click="applyToAll(item)"
                          >Apply to All</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <!-- <div v-if="salesOrder.package.mealsCount">
            <v-layout class="mb-md-5">
              <v-flex xs12 md2>
                <h4 class="font-weight-bolder mb-4 mb-md-0">Meals Count</h4>
              </v-flex>
            </v-layout>
            <v-layout
              v-for="item in salesOrder.package.mealsCount"
              :key="item._id"
              wrap
            >
              <v-flex xs5 md2>
                <label class="black--text text-body-2">
                  {{ item.menuCategory.nameEn }}
                </label>
              </v-flex>
              <v-flex xs6 sm5 md2>
                <v-text-field
                  :label="item.menuCategory.nameEn"
                  :rules="[requiredValidator('meals count')]"
                  solo
                  v-model="item.count"
                  @keypress="isNumber"
                  @change="reCalculateTotalPrice"
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </div> -->

          <v-divider> </v-divider>

          <v-layout row class="mt-8">
            <v-flex xs6 sm3 md6>
              <CustomerUnPaidPackages
                v-if="unPaidPackages.length > 0"
                :unPaidPackages="unPaidPackages"
              />
            </v-flex>
            <v-flex xs6 sm3 md6>
              <v-layout justify-end>
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                  color="secondary"
                  class="black--text text-capitalize text-end"
                  @click="updateSalesorder()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >Save</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>

        <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{
          text
        }}</v-snackbar>
        <!-- </v-container> -->

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btncolor" text @click="close">CLOSE</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  requiredValidator,
  requiredValidatorSelect,
} from "../../../core/methods/validations";
import moment from "moment";
import tz from "moment-timezone";
import CustomerUnPaidPackages from "./CustomerUnPaidPackages.vue";
// import SwitchPlan from "./SwitchPlan.vue";
import { DATE_FORMAT, Utils, TIME_ZONE } from "../../../utils/utils";
export default {
  components: { CustomerUnPaidPackages },
  props: {
    customer_id: {
      required: true,
    },
    salesOrderInfo: {
      require: true,
    },
    allPackages: {
      require: true,
    },
  },
  mounted() {
    this.$root.$on("getCustomerTempPackages", () => {
      this.getCustomerTempPackages();
    });
    this.$root.$on("updateGetCustomerById", () => {
      this.close();
    });
  },
  apollo: {
    getAllCategories() {
      return {
        query: require("@/menu-packages/api/getAllCategories.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          let allCategories = data.getAllCategories.category.filter(
            (x) => x.active == true
          );
          this.allCategories = JSON.parse(JSON.stringify(allCategories));
        },
      };
    },
    getMealSelectionDate() {
      return {
        query: require("./api/getMealSelectionDate.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          let date = data.getMealSelectionDate.mealSelectionDate;
          this.mealSelectionDate = moment(new Date(parseInt(date)));
          let endDate = moment(new Date(parseInt(this.salesOrderInfo.end_date)));
          let mealSelectionDate = this.mealSelectionDate;
          if (endDate <= mealSelectionDate) {
            this.additionalDaysDisabled = true;
          }
        },
      };
    },
  },
  // watch: {
  //   salesOrderInfo: {
  //     handler(newVal) {
  //       debugger
  //       this.salesOrder = newVal;
  //       this.discount = this.salesOrder.discount;

  //       this.endDate = new Date(parseInt(this.salesOrder.end_date));

  //       let additionalDays = "";
  //       if (this.salesOrder.package) {
  //         if (this.salesOrder.package.availableChoices.length > 0) {
  //           additionalDays =
  //             this.salesOrder.package.availableChoices[0].additionalDays;
  //         }
  //       }
  //       //  debugger;
  //       this.additionalDaysIncluded = additionalDays;
  //       this.onAdditionalDaysChange();
  //       this.fDate = moment(
  //         new Date(parseInt(this.salesOrder.start_date))
  //       ).format("YYYY-MM-DD");

  //       this.currentDiscount = this.salesOrder.discount
  //         ? this.salesOrder.discount
  //         : 0;
  //       this.totalPrice = this.salesOrder.net_price.toFixed(3);
  //       this.getCustomerTempPackages();
  //       this.packageCountListing();
  //     },
  //     deep: true,
  //   },
  // },
  data() {
    return {
      snack: false,
      allCategories: [],
      requiredValidatorSelect,
      nowDate: new Date().toISOString().slice(0, 10),
      additionalPrice: "",
      salesOrder: [],
      availableDays: [],
      payableAmount: 0,
      avalilablePaymentMethod: [],
      showPaymentMethod: false,
      paymentMethod: "",
      paymentMethods: [
        {
          value: "ONLINE",
          text: "Online",
        },
        {
          value: "OFFLINE",
          text: "Offline",
        },
        {
          value: "FREE",
          text: "Free",
        },
      ],
      tab: "",
      additionalDays: "",
      additionalDaysIncluded: 0,
      totalAvailableDays: 0,
      endDateLoading: false,
      endDate: "",
      menu1: "",
      tz: tz,
      fDate: "",
      totalPrice: 0,
      discount: 0,
      currentDiscount: 0,
      packageMealCount: [],
      snackColor: "",
      salesOrderTemp: [],
      snackText: "",
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      unPaidPackages: [],
      variables: {
        limit: 25,
        skip: 0,
      },
      availableWeekdays: [],
      listOfDays: [],
      runningPackages: [],
      additionalDaysDisabled: false,
    };
  },
  created() {
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_ONLINE)) {
      const data = this.paymentMethods[0];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_OFFLINE)) {
      const data = this.paymentMethods[1];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_FREE)) {
      const data = this.paymentMethods[2];
      this.avalilablePaymentMethod.push(data);
    }
  },

  methods: {
    isAllowCustomerWeekDays(selectPackage) {
      if (selectPackage.allowedWeekDays) {
        if (selectPackage.allowedWeekDays.length > 0) {
          return true;
        }
      }
      return false;
    },
    applyToAll(category) {
      this.availableDays.forEach((day) => {
        day.allCategories.forEach((element) => {
          if (element._id == category._id) {
            element.count = category.count;
          }
        });
      });
      this.snackbar = true;
      this.snackColor = "success";

      this.text = "Meal count applied to all days.";
    },
    packageCountListing() {
      if (this.salesOrder.package) {
        this.allCategories.forEach((element) => {
          element.count = 0;
        });
        this.listOfDays = [];
        this.availableDays = [];
        let sunday = {
          day: "Sunday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories)),
        };
        let monday = {
          day: "Monday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories)),
        };
        let tuesday = {
          day: "Tuesday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories)),
        };
        let wednesday = {
          day: "Wednesday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories)),
        };
        let thursday = {
          day: "Thursday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories)),
        };
        let friday = {
          day: "Friday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories)),
        };
        let saturday = {
          day: "Saturday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories)),
        };

        this.availableChoices = new Array();
        this.salesOrder.package.availableChoices[0].availableDays.forEach((day) => {
          switch (day) {
            case "Sunday": {
              sunday.allCategories.forEach((category) => {
                let dayChoice = this.salesOrder.package.dayChoices.find(
                  (x) => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    (x) => x.menuCategoryId == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(sunday);
              break;
            }
            case "Monday": {
              monday.allCategories.forEach((category) => {
                let dayChoice = this.salesOrder.package.dayChoices.find(
                  (x) => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    (x) => x.menuCategoryId == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(monday);
              break;
            }
            case "Tuesday": {
              tuesday.allCategories.forEach((category) => {
                let dayChoice = this.salesOrder.package.dayChoices.find(
                  (x) => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    (x) => x.menuCategoryId == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(tuesday);
              break;
            }
            case "Wednesday": {
              wednesday.allCategories.forEach((category) => {
                let dayChoice = this.salesOrder.package.dayChoices.find(
                  (x) => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    (x) => x.menuCategoryId == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(wednesday);
              break;
            }
            case "Thursday": {
              thursday.allCategories.forEach((category) => {
                let dayChoice = this.salesOrder.package.dayChoices.find(
                  (x) => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    (x) => x.menuCategoryId == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(thursday);
              break;
            }
            case "Friday": {
              friday.allCategories.forEach((category) => {
                let dayChoice = this.salesOrder.package.dayChoices.find(
                  (x) => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    (x) => x.menuCategoryId == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(friday);
              break;
            }
            case "Saturday": {
              saturday.allCategories.forEach((category) => {
                let dayChoice = this.salesOrder.package.dayChoices.find(
                  (x) => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    (x) => x.menuCategoryId == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(saturday);
              break;
            }
          }
        });
        this.listOfDays.push(sunday);
        this.listOfDays.push(monday);
        this.listOfDays.push(tuesday);
        this.listOfDays.push(wednesday);
        this.listOfDays.push(thursday);
        this.listOfDays.push(friday);
        this.listOfDays.push(saturday);
      }
    },
    onSalesOrderEdit() {
      this.salesOrder = JSON.parse(JSON.stringify(this.salesOrderInfo));
      this.paymentMethod = "";
      this.additionalPrice = "";
      this.showPaymentMethod = false;
      this.additionalDays = "";
      this.payableAmount = 0;
      this.getCustomerTempPackages();
      this.salesOrderTemp = JSON.parse(JSON.stringify(this.salesOrder));
      this.fDate = moment(new Date(parseInt(this.salesOrder.start_date))).format(
        "YYYY-MM-DD"
      );
      this.endDate = new Date(parseInt(this.salesOrder.end_date));

      this.discount = this.salesOrder.discount;

      this.endDate = new Date(parseInt(this.salesOrder.end_date));

      //  debugger;
      let additionalDays = "";
      if (this.salesOrder.package) {
        if (this.salesOrder.package.availableChoices.length > 0) {
          additionalDays = this.salesOrder.package.availableChoices[0].additionalDays;
        }
      }

      this.additionalDaysIncluded = additionalDays;
      this.totalAvailableDays = this.salesOrder.package.availableChoices[0].availableNumOfDays;

      this.fDate = moment(new Date(parseInt(this.salesOrder.start_date))).format(
        "YYYY-MM-DD"
      );

      this.nowDate = moment().add(3, "days").toDate().toISOString().slice(0, 10);

      this.runningPackages = Utils.getActiveAndPendingCustomerPackages(this.allPackages);
      // debugger;
      if (this.currentPlanStatus(this.salesOrder) == "Upcoming") {
        if (this.runningPackages.activePackage) {
          this.nowDate = moment(
            new Date(parseInt(this.runningPackages.activePackage.end_date))
          )
            .tz(TIME_ZONE)
            .add(1, "days")
            .toDate()
            .toISOString()
            .slice(0, 10);
        }
      }

      this.currentDiscount = this.salesOrder.discount ? this.salesOrder.discount : 0;
      this.totalPrice = this.salesOrder.net_price.toFixed(3);
      this.getCustomerTempPackages();
      this.packageCountListing();
    },
    currentPlanStatus(order) {
      var packageStatus = "Expired";
      if (order.payment_status === "PENDING") {
        packageStatus = "Payment Pending";
      } else {
        if (this.runningPackages.activePackage) {
          if (order._id == this.runningPackages.activePackage._id) {
            packageStatus = "Active";
          }
        }
        if (this.runningPackages.upcomingPackage) {
          if (order._id == this.runningPackages.upcomingPackage._id) {
            packageStatus = "Upcoming";
          }
        }
      }

      return packageStatus;
    },
    onAdditionalDaysChange() {
      let available = this.getAvailableDays();
      this.totalAvailableDays =
        available + parseInt(this.additionalDays ? this.additionalDays : 0);
    },
    getSubscribedDays() {
      let additionalDays = this.salesOrder.package.availableChoices[0].additionalDays;
      if (!additionalDays) {
        additionalDays = 0;
      }
      let available = this.getAvailableDays() - additionalDays;
      return available;
    },
    getAvailableDays() {
      let availableNumOfDays = this.salesOrder.package.availableChoices[0]
        .availableNumOfDays;
      return parseInt(availableNumOfDays);
    },
    getTotalNumberOfDays() {
      let availableDays = parseInt(
        this.salesOrder.package.availableChoices[0].availableNumOfDays
      );

      let currentTotalDays = availableDays;

      let additionalDays = this.additionalDays ? parseInt(this.additionalDays) : 0;

      currentTotalDays = currentTotalDays + additionalDays;

      return currentTotalDays;
    },
    recalculateEndDate() {
      this.onAdditionalDaysChange();
      let availableDays = this.availableDays.map((x) => x.day);
      this.endDateLoading = true;
      this.$apollo
        .mutate({
          mutation: require("./api/reCalculateEndDate.graphql"),
          variables: {
            input: {
              start_date: this.fDate,
              availableDays: availableDays,
              customer: this.$route.query.customerId,
              noOfDays: this.getTotalNumberOfDays(),
              subscribedPackageId: this.salesOrder._id,
            },
          },
        })
        .then((data) => {
          this.endDateLoading = false;
          this.endDate = moment(data.data.reCalculateEndDate, "DD-MM-YYYY").toDate();
          console.log(data.reCalculateEndDate);
        })
        .catch((error) => {
          this.endDateLoading = false;
          console.log(error);
        });
    },
    close() {
      this.show_dialog_box = false;
    },
    isFloatNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    getCustomerTempPackages() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../api/getCustomersTempPackages.graphql"),
          variables: {
            customerId: this.$route.query.customerId,
            subscribed_package_id: this.salesOrder._id,
          },
        })
        .refetch({
          variables: {
            customerId: this.$route.query.customerId,
            subscribed_package_id: this.salesOrder._id,
          },
        })
        .then((data) => {
          this.loading = false;
          if (data.data.getCustomersTempPackages) {
            this.unPaidPackages = data.data.getCustomersTempPackages;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    onPaymentMethodChange() {
      if (this.paymentMethod == "FREE") {
        this.payableAmount = 0;
      } else {
        this.reCalculateTotalPrice();
      }
    },

    reCalculateTotalPrice() {
      let additionalPackagePrice = this.additionalPrice
        ? parseFloat(this.additionalPrice)
        : 0;

      let discount = this.discount ? parseFloat(this.discount) : 0;

      let payableAmount = this.additionalPrice - discount + this.currentDiscount;

      this.payableAmount = payableAmount.toFixed(3);
      if (payableAmount > 0) {
        this.showPaymentMethod = true;
      } else {
        this.showPaymentMethod = false;
      }

      if (this.paymentMethod == "FREE") {
        this.payableAmount = 0;
      }

      this.totalPrice = (
        this.salesOrder.net_price -
        discount +
        this.currentDiscount +
        additionalPackagePrice
      ).toFixed(3);
    },
    updateSalesorder() {
      let customerPackage = {};
      // let mealsCountList = new Array(0);
      // this.salesOrder.package.mealsCount.forEach((element) => {
      //   let mealsCount = {
      //     id: element._id,
      //     count: element.count,
      //     menuCategory: element.menuCategory.menuCategoryId,
      //   };
      //   mealsCountList.push(mealsCount);
      // });

      let dayChoices = new Array();
      this.availableDays.forEach((dayChoice) => {
        let mealCounts = new Array();
        dayChoice.allCategories.forEach((element) => {
          if (parseInt(element.count) > 0) {
            let mealCount = {
              count: parseInt(element.count),
              category: element._id,
            };
            mealCounts.push(mealCount);
          }
        });
        let dayChoiceItem = {
          day: dayChoice.day,
          mealCount: mealCounts,
        };
        dayChoices.push(dayChoiceItem);
      });

      let meanInfo = {
        carbs: this.salesOrder.package.meanInfo.carbs,
        protein: this.salesOrder.package.meanInfo.protein,
      };

      customerPackage.dayChoices = dayChoices;

      let availableDays = this.availableDays.map((x) => x.day);

      let isValid = true;
      availableDays.forEach((day) => {
        let hasMeal = false;
        let mealCount = this.availableDays.find((x) => x.day == day);
        mealCount.allCategories.forEach((category) => {
          if (parseInt(category.count) > 0) {
            hasMeal = true;
          }
        });

        if (!hasMeal) {
          this.snackbar = true;
          this.snackColor = "error";
          this.text = "Please add atleast one meal on : " + mealCount.day;
          isValid = false;
        }
      });

      let numberOfDays = this.additionalDays ? parseFloat(this.additionalDays) : 0;

      let numberOfAlreadyAddedDays = this.additionalDaysIncluded
        ? parseFloat(this.additionalDaysIncluded)
        : 0;

      this.discount == "" ? 0 : this.discount;

      this.additionalPrice == "" ? 0 : this.additionalPrice;

      if (this.paymentMethod == "FREE") {
        this.additionalPrice = 0;
        this.reCalculateTotalPrice();
      }
      if (isValid) {
        this.btnloading = true;
        let subPackages = {
          _id: this.salesOrder._id,
          meanInfo: meanInfo,
          package: customerPackage,
          availableDays: availableDays,
          package_price: parseFloat(this.salesOrder.package_price),
          discount: parseFloat(this.discount),
          net_price: parseFloat(this.totalPrice),
          numberOfDays: numberOfDays + numberOfAlreadyAddedDays,
          additionalPrice: parseFloat(this.payableAmount),
          paymentMethod: this.paymentMethod == "" ? "FREE" : this.paymentMethod,
          start_date: this.fDate,
        };

        if (!this.valid) return false;
        let mutation = require("../../api/createTempCustomerSalesorder.graphql");
        if (parseFloat(this.payableAmount) == 0 || subPackages.paymentMethod == "FREE") {
          mutation = require("../../api/updateCustomerSalesorder.graphql");
        }
        this.$apollo
          .mutate({
            mutation: mutation,
            variables: {
              customerId: this.customer_id,
              subscribedPackagesInput: subPackages,
            },
          })
          .then(() => {
            this.$root.$emit("updateGetCustomerById");
            this.$root.$emit("getCustomerTempPackages");
            this.snackbar = true;
            this.snackColor = "success";
            this.text = "Customer details updated";
            this.btnloading = false;
            this.close();
          })
          .catch((error) => {
            this.$root.$emit("updateGetCustomerById");
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
            this.isNewAddress = false;
            this.close();
          });
      }
    },
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format(DATE_FORMAT) : "";
    },
  },
};
</script>

<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
