var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs8":"","sm4":"","md3":""}},[_c('v-select',{attrs:{"items":_vm.packageStatuses,"label":"Status","item-text":"text","item-value":"value","attach":"","solo":"","dense":""},on:{"change":_vm.onStatusChange},model:{value:(_vm.packageStatus),callback:function ($$v) {_vm.packageStatus=$$v},expression:"packageStatus"}})],1),_c('v-flex',{staticClass:"me-2 ml-3",attrs:{"xs12":"","sm5":"","md2":""}},[_c('v-autocomplete',{attrs:{"items":_vm.adminUsers,"label":"User","item-text":"name","item-value":"_id","attach":"","dense":"","solo":"","clearable":""},on:{"change":_vm.onStatusChange},model:{value:(_vm.createdBy),callback:function ($$v) {_vm.createdBy=$$v},expression:"createdBy"}})],1),_c('v-flex',{attrs:{"xs8":"","sm3":"","md3":"","lg2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ms-sm-2",attrs:{"value":_vm.fromDate,"label":"From","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.fDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":_vm.selectFromDate},model:{value:(_vm.fDate),callback:function ($$v) {_vm.fDate=$$v},expression:"fDate"}})],1)],1),_c('v-flex',{attrs:{"xs8":"","sm3":"","md3":"","lg2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ms-sm-2",attrs:{"value":_vm.toDate,"label":"To","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.tDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":_vm.selectToDate},model:{value:(_vm.tDate),callback:function ($$v) {_vm.tDate=$$v},expression:"tDate"}})],1)],1),_c('v-flex',{attrs:{"sm12":"","md2":"","lg2":""}},[_c('v-layout',{staticClass:"text-end"},[(_vm.hasRole(_vm.AdminUserPermissions.SALES_ORDER_EXPORT))?_c('ExportSalesOrder',{attrs:{"headers":_vm.headers,"status":_vm.variables.status,"createdBy":_vm.createdBy,"search":_vm.search,"packageStatus":_vm.packageStatus,"fDate":_vm.fDate,"tDate":_vm.tDate}}):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.salesOrderList,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(_vm.getDate(new Date(parseInt(item.date))),"MMM DD YYYY hh:mm"))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.startDate)),"MMM DD YYYY")))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.endDate)),"MMM DD YYYY")))]}},{key:"item.package_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.package_price)))]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.discount + item.promoCodeDiscount)))]}},{key:"item.net_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.net_price))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.customer.phone ? item.customer.phone : "--")+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('PriceSplitup',{attrs:{"subscribedPackage":item}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [(item.createdBy)?_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.createdBy.firstnameEn))+" "+_vm._s(_vm.capitalizeFirstLetter(item.createdBy.lastnameEn))+" ")]):_vm._e()]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.paymentStatus == 'ACTIVE'
            ? 'green--text'
            : item.paymentStatus == 'Payment Pending'
            ? 'orange--text'
            : item.paymentStatus == 'UPCOMING'
            ? 'blue--text'
            : item.paymentStatus == 'EXPIRED'
            ? 'red--text'
            : ''},[_vm._v(" "+_vm._s(item.paymentStatus))])]}},{key:"item.referredUser",fn:function(ref){
            var item = ref.item;
return [(item.referredByUser)?_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.referredByUser.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.referredByUser.lastnameEn))+" ")]):_c('span',[_vm._v("--")])]}},{key:"item.referredUserRole",fn:function(ref){
            var item = ref.item;
return [(item.referredByUser)?_c('span',[_vm._v(" "+_vm._s(item.referredByUser.role)+" ")]):_c('span',[_vm._v("--")])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [(!item.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CustomerDetails',
          params: { customerId: item.customerId },
          query: { customerId: item.customerId },
        }}},[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.lastnameEn))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.lastnameEn))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }