<template>
  <div>
    <div class="d-flex">
      <v-btn
        :disabled="disabled"
        @click="printIngredientReport"
        color="btncolor"
        :width="$vuetify.breakpoint.xs ? 100 : 120"
        :small="$vuetify.breakpoint.xs"
        class="white--text text-capitalize me-2 me-sm-3"
      >
        <v-icon class="me-1">mdi-file-document</v-icon>Print
      </v-btn>

      <v-btn
        :disabled="disabled"
        @click="exportExcel"
        :width="$vuetify.breakpoint.xs ? 100 : 120"
        :small="$vuetify.breakpoint.xs"
        color="btncolor"
        class="white--text text-capitalize me-sm-4"
      >
        <v-icon size="20" class="me-1">mdi-export</v-icon>Export
      </v-btn>
    </div>
    <div style="display: none">
      <div id="printIng">
        <table
          v-if="ingredientsList.length > 0"
          id="printPage"
          border="1"
          style="border-collapse: collapse; width: 250mm"
        >
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.value"
                :style="`width: ${header.printWidth};vertical-align: middle;`"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in ingredientsList"
              :key="item.id"
              style="width: 20px"
            >
              <td
                style="text-align: center; vertical-align: middle; height: 30px"
              >
                {{ item.slNo }}
              </td>
              <td style="vertical-align: middle">
                <span style="padding-left: 5px"> {{ item.name }}</span>
              </td>
              <td
                style="vertical-align: middle; text-align: center"
                v-for="(meanInfo, index) in meanInfos"
                :key="index"
              >
                {{ getCellValue(item, meanInfo) }}
              </td>
              <td style="text-align: center; vertical-align: middle">
                {{ getTotalValue(item) }}
              </td>
            </tr>
          </tbody>
        </table>
        <!--           
        <v-data-table :headers="headers" :items="ingredientsList">
          <template v-slot:body="props">
            <tbody>
              <tr v-for="item in props.items" :key="item.id">
                <td v-for="(header, index) in headers" :key="index">
                  <span v-if="index == 0"> {{ item.name }}</span>
                  <span v-else>{{ getCellValue(item, header) }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    ingredientsList: { require: true },
    disabled: { require: true },
    headers: { require: true },
    meanInfos: { require: true },
  },
  methods: {
    exportExcel() {
      Utils.htmlToExcel("printIng", "Ingredient");
    },
    getCellValue(item, header) {
      let meansInfo = item.meansInfo;
      let info = meansInfo.find(
        (x) => x.protein == header.protein && x.carbs == header.carbs
      );
      return info.quantity;
    },
    getTotalValue(item) {
      let sum = item.meansInfo
        .map((x) => x.quantity)
        .reduce((a, b) => a + b, 0);
      return sum;
    },
    printIngredientReport() {
      const divToPrint = document.getElementById("printIng");
      const newWin = window.open("", "Print-Window");
      newWin.document.open();
      newWin.document.write("<html><head><title>Receipt</title>");

      newWin.document.write("</head><body>");
      newWin.document.write(divToPrint.innerHTML);
      newWin.document.write("</body></html>");
      newWin.document.close();
      newWin.focus();
      newWin.print();
      setTimeout(function () {
        newWin.close();
      }, 10);
    },
  },
};
</script>
<style scoped></style>
