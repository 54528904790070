<template>
  <div class="box">
    <v-layout justify-end class>
      <v-flex xs12 sm7 md3 class="mt-5">
        <v-text-field
          solo
          dense
          name="input-7-4"
          label="Search Phone"
          type="number"
          @keydown.enter="runSearch()"
          v-model="search"
        ></v-text-field>
      </v-flex>

      <v-flex xs8 sm3 md1 lg3 class="ml-2 mt-5">
        <v-select
          :items="['All', 'ONLINE', 'OFFLINE']"
          v-model="paymentMethod"
          item-value="value"
          item-text="text"
          label="All"
          solo
          @change="runSearch"
          dense
        ></v-select>
      </v-flex>

      <v-flex xs8 sm3 md1 lg2 class="ml-2 mt-5">
        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fromDate"
              label="From"
              solo
              class="ms-sm-1"
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="fDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="fDate"
            @change="selectFromDate"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs8 sm3 md1 lg2 class="ml-2 mt-5">
        <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="toDate"
              label="To"
              class="ms-sm-1"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="tDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="tDate"
            @change="selectToDate"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex class="ml-2 mt-5">
        <ExportAccount
          :fDate="fDate"
          :tDate="tDate"
          :headers="headers"
          :search="search"
          :accountType="'invoice'"
        />
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="accountList"
      class="elevation-1 mt-2"
      :options.sync="options"
      :mobile-breakpoint="0"
      :items-per-page="25"
      :server-items-length="totalItemsCount"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.siNo`]="{ item }">
        {{
          accountList
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            options.itemsPerPage * (options.page - 1)
        }}
      </template>
      <template v-slot:[`item.customerName`]="{ item }">
        <router-link
          v-if="item.user"
          :to="{
            name: 'CustomerDetails',
            query: { customerId: item.customer }
          }"
          class="mx-auto"
        >
          {{ capitalizeFirstLetter(item.user.firstnameEn) }}
          {{ capitalizeFirstLetter(item.user.lastnameEn) }}
        </router-link>
      </template>
      <template v-slot:[`item.contact`]="{ item }">
        {{ item.user.phone }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ dateFormat(new Date(parseInt(item.createdAt))) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ numberWithCommas(item.amount) }}
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        {{ numberWithCommas(item.discount) }}
      </template>
      <!-- <template v-slot:[`item.taxes`]="{ item }">
        {{ numberWithCommas(item.tax) }}
      </template> -->
      <template v-slot:[`item.total`]="{ item }">
        {{ numberWithCommas(item.total) }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <InvoiceDownload :invoice="item" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import InvoiceDownload from "../components/InvoiceDownload.vue";
import ExportAccount from "../components/ExportAccount.vue";

export default {
  components: {
    InvoiceDownload,
    ExportAccount
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format("DD/MM/YYYY") : "";
    },
    toDate() {
      return this.tDate ? moment(this.tDate).format("DD/MM/YYYY") : "";
    }
  },
  data: () => ({
    accountList: [],
    fDate: format(
      parseISO(
        moment()
          .subtract(7, "days")
          .toDate()
          .toISOString()
      ),
      "yyyy-MM-dd"
    ),
    tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    date: "",
    menu1: false,
    menu2: false,
    loading: true,
    headers: [
      {
        text: "Si No",
        value: "siNo",
        sortable: false
      },
      { text: "Customer Name", value: "customerName" },
      { text: "Contact", value: "contact", sortable: false },
      { text: "Date and Time", value: "date" },
      { text: "Amount", value: "amount" },
      { text: "Discount", value: "discount" },
      { text: "Total ", value: "total" },

      { text: "Payment Method", value: "paymentMethod" },

      { text: "Action ", value: "action", sortable: false }
    ],
    totalItemsCount: 0,
    search: "",
    variables: {
      limit: 25,
      skip: 0
    },
    options: {
      itemsPerPage: 25,
      page: 1
    },
    paymentMethod: ""
  }),
  watch: {
    options: {
      handler() {
        if (!this.loading) {
          this.variables.limit = this.options.itemsPerPage;
          this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage;
          (this.variables.sortDesc = this.options.sortDesc
            ? this.options.sortDesc[0]
            : ""),
            (this.variables.sortBy = this.options.sortBy
              ? this.options.sortBy[0]
              : ""),
            this.getAllInvoiceTransaction();
        }
      },
      deep: true
    }
  },
  created() {
    this.getAllInvoiceTransaction();
  },
  methods: {
    selectFromDate() {
      this.menu1 = false;
      this.getAllInvoiceTransaction();
    },
    selectToDate() {
      this.menu2 = false;
      this.getAllInvoiceTransaction();
    },
    clearSearch() {
      this.runSearch();
    },
    runSearch() {
      this.menu1 = false;
      this.menu2 = false;
      this.getAllInvoiceTransaction();
    },
    getAllInvoiceTransaction() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllInvoiceTransaction.graphql"),
          variables: {
            skip: this.variables.skip,
            limit: this.variables.limit,
            search: this.search,
            fromDate: this.fDate,
            toDate: this.tDate
          }
        })
        .refetch({
          skip: this.variables.skip,
          limit: this.variables.limit,
          search: this.search,
          fromDate: this.fDate,
          toDate: this.tDate,
          paymentMethod: this.paymentMethod
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllInvoiceTransactions) {
            this.accountList =
              data.data.getAllInvoiceTransactions.transactionList;
            this.totalItemsCount =
              data.data.getAllInvoiceTransactions.totalCount;
          }
          this.loading = false;
        });
    },
    dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY h:mm:ss A");
    }
  }
};
</script>
<style>
.box {
  background-color: #e5e5e5;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
