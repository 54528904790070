<template>
  <v-container class="bg px-0" fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout class="mt-2 ms-sm-n4 mb-3 mb-sm-0" wrap>
      <v-flex xs7 sm4 md3 class="ms-sm-5 me-5">
        <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate"
              label="Date"
              readonly
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            color="secondary"
            v-model="date"
            @change="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs7 sm3 md2>
        <v-select
          :items="reportFilters"
          label="Report Type"
          v-model="reportFilter"
          item-text="text"
          item-value="value"
          dense
          filled
          solo
          attach
        ></v-select>
      </v-flex>

      <v-flex xs12 md4 lg2 class="ml-2">
        <v-autocomplete
          label="Package"
          v-model="packageId"
          solo
          dense
          attach
          filled
          :items="packagesList"
          item-text="titleEn"
          item-value="_id"
        >
        </v-autocomplete>
      </v-flex>

      <v-flex xs12 sm3 md2 class="ms-sm-5 ms-md-0">
        <v-btn
          class="ms-sm-5 white--text text-capitalize"
          color="secondary"
          width="150"
          @click="searchDelivery"
          >Search
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex
        v-flex
        xs12
        sm12
        md8
        lg7
        class="mb-sm-5 mb-sm-0 mt-sm-n3"
        v-if="reportGeneratedDate"
      >
        <v-layout
          class="ms-md-1"
          v-if="parseInt(reportGeneratedDate).toString() != 'NaN'"
          wrap
        >
          <v-flex xs12 sm5 md5 lg6>
            <label class="pe-lg-10">
              Report generated on
              {{
                new Date(parseInt(reportGeneratedDate))
                  | moment("MMM DD YYYY HH:mm a")
              }}</label
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 md8 lg7 class="mb-sm-5 mb-sm-0" v-else>
        <v-row>
          <v-flex xs12 sm5 md5 lg6>
            <label class="ms-md-4">Report not generated</label>
          </v-flex>
          <v-flex
            xs12
            sm4
            md3
            lg6
            class="mt-3 mt-sm-0 ms-md-7 ms-lg-0 ms-xl-n6"
          >
          </v-flex>
        </v-row>
      </v-flex>
      <!-- </v-layout>
    <v-layout> -->
      <v-flex
        xs12
        sm12
        lg5
        xl5
        class="ms-n3 ms-sm-0 mt-12 mt-sm-4 mt-lg-0 text-end d-flex"
      >
        <v-row justify="end" v-if="allCategoryResponse">
          <PrintOrderItems
            :date="date"
            :disabled="allCategoryResponse.length == 0"
            :reportList="allCategoryResponse"
            :headers="headers"
            :meanInfos="meanInfos"
          />
          <MealStickerPrint
            :reportList="reportList"
            :disabled="reportList.length == 0"
            :deliveryDate="date"
            class="text-end"
            :reportFilter="reportFilter"
            :packageId="packageId"
          />
        </v-row>
      </v-flex>
      <!-- <v-flex
        xs12
        sm4
        md4
        lg2
        xl1
        class="me-n15 ms-n3 ms-sm-0 mt-3 mt-sm-4 mt-lg-0 text-end"
      >
       
      </v-flex> -->
    </v-layout>

    <v-data-table
      class="mt-8"
      v-if="reportList"
      :mobile-breakpoint="0"
      :headers="headers"
      :items="reportList"
      :items-per-page="25"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:body="props">
        <tbody>
          <tr v-for="item in props.items" :key="item.id">
            <td v-for="(header, index) in headers" :key="index">
              <div v-if="index == 1">
                <span v-if="item.isCategory" style="padding: 4px 2px 0px 4px">
                  <!-- <strong>
                    <b>{{ item.itemName }}</b>
                  </strong> -->
                </span>
                <span v-else-if="item.isParent">
                  {{ item.itemName }}
                </span>

                <span v-else>
                  <ul>
                    <li>
                      {{ item.itemName }}
                      {{ getDislikes(item.dislikedIngredients) }}
                    </li>
                  </ul>
                </span>
              </div>
              <div v-else-if="index == 0">
                <span v-if="item.isCategory">
                  <strong> {{ item.itemName }}</strong>
                </span>
                <span v-else-if="item.isParent">
                  {{ item.count }}
                </span>
              </div>

              <div v-else-if="header.value == 'total' && !item.isCategory">
                {{ getTotalValue(item.meanInfo) }}
              </div>
              <div v-else>
                <span v-if="!item.isCategory">
                  {{ getMeanInfo(header, item.meanInfo) }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-row v-else>
      <h3>No data found</h3>
    </v-row>

    <v-dialog v-model="showErrorDialog" max-width="500">
      <v-card class="pa-3">
        <v-row>
          <v-card-title class="text-h6 primary--text mt-n4"
            >Meals Log</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="btncolor" text @click="showErrorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <ul class="mt-5" v-if="errorLog.length > 0">
          <li v-for="item in errorLog" :key="item">{{ item }}</li>
        </ul>
        <div v-else>All meals are generated successfully!</div>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="error">{{
      text
    }}</v-snackbar>
  </v-container>
</template>
<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import VueLodash from "vue-lodash";
import Vue from "vue";
import lodash from "lodash";
import PrintOrderItems from "./PrintOrderItems.vue";
import MealStickerPrint from "./MealStickerPrint.vue";
import { DATE_FORMAT } from "../../utils/utils";

Vue.use(VueLodash, { name: "custom", lodash: lodash });
export default Vue.extend({
  components: { PrintOrderItems, MealStickerPrint },
  data() {
    return {
      menu: false,
      snackbar: false,
      text: "",
      loading: false,
      lodash: lodash,
      errorLog: [],
      showErrorDialog: false,
      btnloading: false,
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      nowDate: new Date().toISOString().slice(0, 10),
      headers: [],
      reportList: [],
      reportGeneratedDate: "",
      meanInfos: [],
      reportFilter: "ALL_MEAL_STICKER",
      allCategoryResponse: [],
      reportFilters: [
        { text: "ALL", value: "ALL_MEAL_STICKER" },
        { text: "WITH_DISLIKE", value: "MEAL_STICKER_WITH_DISLIKE" },
        { text: "WITHOUT_DISLIKE", value: "MEAL_STICKER_WITHOUT_DISLIKE" },
      ],
      packagesList: [],
      packageId: "",
    };
  },
  apollo: {
    getAllPackages() {
      return {
        query: require("@/menu-packages/api/getAllPackages.graphql"),
        result({ data }) {
          this.packagesList = data.getAllPackages.packages;
        },
      };
    },
  },
  created() {
    this.getOrderMealDetailsByDate();
  },
  methods: {
    getOrderMealDetailsByDate() {
      this.reportList = [];
      this.loading = true;
      const dateTime = moment(`${this.date}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      this.$apollo
        .watchQuery({
          query: require("../api/getOrderMealDetailsByDate.graphql"),
          variables: { date: modifiedDate, package: this.packageId },
        })
        .refetch({ variables: { date: modifiedDate } })
        .then((data) => {
          this.loading = false;
          this.reportGeneratedDate = "";
          if (data.data.getOrderMealDetailsByDate) {
            let resultList = new Array();
            //    let resultListOrderByCategory = new Array();

            this.meanInfos = data.data.getOrderMealDetailsByDate.meanInfos;
            let count = 1;
            if (data.data.getOrderMealDetailsByDate.orderMeals)
              data.data.getOrderMealDetailsByDate.orderMeals.forEach(
                (order) => {
                  if (this.reportFilter == "ALL_MEAL_STICKER") {
                    let parentMeanInfo = order.varieties.map((x) => x.meanInfo);

                    let item = {
                      isParent: true,
                      itemName: order.nameEn,
                      dislikedIngredients: [],
                      meanInfo: parentMeanInfo,
                      count: count,
                      menuCategory: order.menuCategory,
                    };

                    resultList.push(item);

                    order.varieties.forEach((element) => {
                      let subIitem = {
                        isParent: false,
                        itemName: order.nameEn,
                        dislikedIngredients: element.dislikedIngredients,
                        meanInfo: element.meanInfo,
                        orderNumber: element.orderNumber,
                        menuCategory: order.menuCategory,
                      };
                      resultList.push(subIitem);
                    });
                    count = count + 1;
                  }

                  if (this.reportFilter == "MEAL_STICKER_WITH_DISLIKE") {
                    let parentMeanInfo = order.varieties
                      .filter((x) => x.dislikedIngredients.length > 0)
                      .map((x) => x.meanInfo);

                    let item = {
                      isParent: true,
                      itemName: order.nameEn,
                      dislikedIngredients: [],
                      meanInfo: parentMeanInfo,
                      count: count,
                      menuCategory: order.menuCategory,
                    };

                    let isMasterAdded = false;
                    order.varieties.forEach((element) => {
                      let hasDislike = false;
                      if (element.dislikedIngredients.length > 0) {
                        hasDislike = true;
                      }
                      let subIitem = {
                        isParent: false,
                        itemName: order.nameEn,
                        dislikedIngredients: element.dislikedIngredients,
                        meanInfo: element.meanInfo,
                        orderNumber: element.orderNumber,
                        menuCategory: order.menuCategory,
                      };
                      if (hasDislike) {
                        if (isMasterAdded == false) {
                          resultList.push(item);
                          isMasterAdded = true;
                        }
                        resultList.push(subIitem);
                      }
                    });
                    count = count + 1;
                  }
                  if (this.reportFilter == "MEAL_STICKER_WITHOUT_DISLIKE") {
                    let parentMeanInfo = order.varieties
                      .filter((x) => x.dislikedIngredients.length == 0)
                      .map((x) => x.meanInfo);
                    let item = {
                      isParent: true,
                      itemName: order.nameEn,
                      dislikedIngredients: [],
                      meanInfo: parentMeanInfo,
                      count: count,
                      menuCategory: order.menuCategory,
                    };

                    let isMasterAdded = false;
                    order.varieties.forEach((element) => {
                      let hasContainNoDislike = false;
                      if (element.dislikedIngredients.length == 0) {
                        hasContainNoDislike = true;
                      }
                      let subIitem = {
                        isParent: false,
                        itemName: order.nameEn,
                        dislikedIngredients: element.dislikedIngredients,
                        meanInfo: element.meanInfo,
                        orderNumber: element.orderNumber,
                        menuCategory: order.menuCategory,
                      };
                      if (hasContainNoDislike) {
                        if (isMasterAdded == false) {
                          resultList.push(item);
                          isMasterAdded = true;
                        }
                        resultList.push(subIitem);
                      }
                    });
                    count = count + 1;
                  }
                }
              );

            let groupByCategory = this.lodash.groupBy(
              data.data.getOrderMealDetailsByDate.orderMeals,
              (x) => x.menuCategory.menuCategoryId
            );

            let allCategories =
              data.data.getOrderMealDetailsByDate.orderMeals.map(
                (x) => x.menuCategory.menuCategoryId
              );
            count = 0;
            allCategories = this.lodash.uniq(allCategories);

            let allCategoryResponse = new Array();

            allCategories.forEach((mealWithCategory) => {
              let allOrdes = groupByCategory[mealWithCategory];
              let allOrderList = new Array();

              if (this.reportFilter == "ALL_MEAL_STICKER") {
                allOrdes.forEach((order) => {
                  let parentMeanInfo = order.varieties.map((x) => x.meanInfo);
                  let orderVerities = new Array();
                  order.varieties.forEach((element) => {
                    let subIitem = {
                      isParent: false,
                      itemName: order.nameEn,
                      dislikedIngredients: element.dislikedIngredients,
                      meanInfo: element.meanInfo,
                      orderNumber: element.orderNumber,
                      menuCategory: order.menuCategory,
                    };
                    //
                    orderVerities.push(subIitem);
                  });
                  count = count + 1;

                  let item = {
                    isParent: true,
                    itemName: order.nameEn,
                    dislikedIngredients: [],
                    orderVerities: orderVerities,
                    meanInfo: parentMeanInfo,
                    count: count,
                  };
                  allOrderList.push(item);
                });

                let itemCategory = {
                  categoryName: allOrdes[0].menuCategory.nameEn,
                  orders: allOrderList,
                };
                allCategoryResponse.push(itemCategory);
              } else if (this.reportFilter == "MEAL_STICKER_WITH_DISLIKE") {
                allOrdes.forEach((order) => {
                  let parentMeanInfo = order.varieties
                    .filter((x) => x.dislikedIngredients.length > 0)
                    .map((x) => x.meanInfo);

                  let orderVerities = new Array();

                  order.varieties.forEach((element) => {
                    let hasDislike = false;
                    if (element.dislikedIngredients.length > 0) {
                      hasDislike = true;
                    }
                    let subIitem = {
                      isParent: false,
                      itemName: order.nameEn,
                      dislikedIngredients: element.dislikedIngredients,
                      meanInfo: element.meanInfo,
                      orderNumber: element.orderNumber,
                      menuCategory: order.menuCategory,
                    };
                    //
                    if (hasDislike) {
                      orderVerities.push(subIitem);
                    }
                  });
                  if (orderVerities.length > 0) {
                    count = count + 1;

                    let item = {
                      isParent: true,
                      itemName: order.nameEn,
                      dislikedIngredients: [],
                      orderVerities: orderVerities,
                      meanInfo: parentMeanInfo,
                      count: count,
                    };

                    allOrderList.push(item);
                  }
                });

                let itemCategory = {
                  categoryName: allOrdes[0].menuCategory.nameEn,
                  orders: allOrderList,
                };
                allCategoryResponse.push(itemCategory);
              } else if (this.reportFilter == "MEAL_STICKER_WITHOUT_DISLIKE") {
                allOrdes.forEach((order) => {
                  let parentMeanInfo = order.varieties
                    .filter((x) => x.dislikedIngredients.length == 0)
                    .map((x) => x.meanInfo);

                  let orderVerities = new Array();

                  order.varieties.forEach((element) => {
                    let hasContainNoDislike = false;
                    if (element.dislikedIngredients.length == 0) {
                      hasContainNoDislike = true;
                    } else {
                      hasContainNoDislike = false;
                    }
                    let subIitem = {
                      isParent: false,
                      itemName: order.nameEn,
                      dislikedIngredients: element.dislikedIngredients,
                      meanInfo: element.meanInfo,
                      orderNumber: element.orderNumber,
                      menuCategory: order.menuCategory,
                    };
                    //
                    if (hasContainNoDislike) {
                      orderVerities.push(subIitem);
                    }
                  });
                  if (orderVerities.length > 0) {
                    count = count + 1;

                    let item = {
                      isParent: true,
                      itemName: order.nameEn,
                      dislikedIngredients: [],
                      orderVerities: orderVerities,
                      meanInfo: parentMeanInfo,
                      count: count,
                    };

                    allOrderList.push(item);
                  }
                });

                let itemCategory = {
                  categoryName: allOrdes[0].menuCategory.nameEn,
                  orders: allOrderList,
                };
                allCategoryResponse.push(itemCategory);
              }
            });

            this.allCategoryResponse = allCategoryResponse;
            this.reportList = resultList;
            //   this.reportList = resultList;
            this.updateHeaders();

            if (data.data.getOrderMealDetailsByDate.generatedDate) {
              this.reportGeneratedDate =
                data.data.getOrderMealDetailsByDate.generatedDate;
            }
          }
        })
        .catch((error) => {
          this.reportGeneratedDate = "";
          this.reportList = null;
          this.allCategoryResponse = null;
          this.loading = false;
          console.log(error);
        });
    },

    updateHeaders() {
      let header = [
        { text: "Sl.No", value: "slNo", width: 80 },
        { text: "Item Name", value: "itemName", width: 400 },
      ];
      if (this.meanInfos)
        this.meanInfos.forEach((element) => {
          let item = {
            text: `P${element.protein}/C${element.carbs}`,
            value: `${element.protein}/${element.carbs}`,
            protein: element.protein,
            carbs: element.carbs,
          };
          header.push(item);
        });
      let total = { text: "Total", value: "total" };
      header.push(total);
      this.headers = header;
    },

    getTotalValue(item) {
      item = this.lodash.flatten(item, true);
      let sum = item.map((x) => x.count).reduce((a, b) => a + b, 0);
      return sum;
    },
    getMeanInfo(type, meanInfoList) {
      meanInfoList = this.lodash.flatten(meanInfoList, true);
      meanInfoList = meanInfoList.filter(
        (x) => x.protein == type.protein && x.carbs == type.carbs
      );

      if (meanInfoList.length > 0) {
        if (meanInfoList.length > 1) {
          let count = 0;
          meanInfoList.forEach((element) => {
            count = count + element.count;
          });
          return count;
        }
        return meanInfoList[0].count;
      }
      return 0;
    },

    generateReport() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../../delivery/api/generateDeliveryReport.graphql"),
          variables: {
            date: this.date,
            isInstantView: false,
          },
        })
        .then((data) => {
          this.btnloading = false;
          if (data.data.generateDeliveryReport) {
            if (data.data.generateDeliveryReport.errors) {
              this.errorLog = data.data.generateDeliveryReport.errors;
              this.showErrorDialog = true;
            }
          }
          this.searchDelivery();
        })
        .catch((error) => {
          this.searchDelivery();
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    isAllowToGenerateReport() {
      if (this.btnloading == false) {
        this.btnloading = true;
        this.$apollo
          .query({
            query: require("../../delivery/api/isAllowToGenerateReport.graphql"),
            variables: {
              date: this.date,
            },
          })
          .then((data) => {
            this.btnloading = false;
            if (data.data.isAllowToGenerateReport) {
              this.generateReport();
            } else {
              this.text = `Please generate report upto ${this.date}`;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },
    getDislikes(dislikes) {
      let dislikeNames = new Array();
      dislikes.forEach((element) => {
        dislikeNames.push(`No ${element.nameEn}`);
      });
      let joined = dislikeNames.join(",");
      let response = "";
      if (dislikes.length > 0) {
        response = `( ${joined} )`;
      }
      return response;
    },
    searchDelivery() {
      this.getOrderMealDetailsByDate();
    },
  },

  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    },
    reportWithSno() {
      if (this.reportList == null) return [];
      return this.reportList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
});
</script>

<style scoped>
table.blueTable {
  border: 1px solid #1c6ea4;
  background-color: #eeeeee;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table.blueTable td,
table.blueTable th {
  border: 1px solid #aaaaaa;
  padding: 3px 2px;
}

table.blueTable tbody td {
  font-size: 13px;
}

table.blueTable tr:nth-child(even) {
  background: #d0e4f5;
}

table.blueTable thead {
  background: #1c6ea4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  background: -webkit-linear-gradient(
    top,
    #5592bb 0%,
    #327cad 66%,
    #1c6ea4 100%
  );
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  border-bottom: 2px solid #444444;
}

table.blueTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  border-left: 2px solid #d0e4f5;
}

table.blueTable thead th:first-child {
  border-left: none;
}

table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background: #d0e4f5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: -webkit-linear-gradient(
    top,
    #dcebf7 0%,
    #d4e6f6 66%,
    #d0e4f5 100%
  );
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  border-top: 2px solid #444444;
}

table.blueTable tfoot td {
  font-size: 14px;
}

table.blueTable tfoot .links {
  text-align: right;
}

table.blueTable tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}
</style>
<style >
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>