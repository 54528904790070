<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <div v-if="hasRole(AdminUserPermissions.PUSH_NOTIFICATION_SEND)">
      <h2 class="secondary--text">Push Notification</h2>
      <app-notification-form
        class="ms-n10 mt-sm-n10 mt-md-n3"
      ></app-notification-form>
    </div>
    <v-layout class="mt-2" wrap>
      <h2 class="secondary--text mb-6">Notification History</h2>
    </v-layout>
    <app-notification-table></app-notification-table>
  </v-container>
</template>
<script>
import NotificationForm from "../components/NotificationForm.vue";
import NotificationHistoryTable from "../components/NotificationHistoryTable.vue";
export default {
  components: {
    "app-notification-form": NotificationForm,
    "app-notification-table": NotificationHistoryTable
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PUSH_NOTIFICATION_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
