<template>
  <v-container>
    <v-btn icon color="secondary" @click="printInvoice">
      <v-icon color="btncolor" class="ms-2">mdi-download</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { printInvoice } from "../methods/a4_invoice";
var QRCode = require("qrcode");
import moment from "moment";
export default {
  components: {},

  props: {
    invoice: {
      required: true,
    },
  },

  created() {},
  methods: {
    printInvoice() {
      debugger;
      let self = this;
      let qrCodeString = `Supplier Name : ${this.invoice.companyInfo.name}
          \nCustomer Name : ${this.invoice.customerInfo.firstnameEn} ${
        this.invoice.customerInfo.lastnameEn
      }
          \nInvoice Date : ${moment(new Date(parseInt(this.invoice.createdAt))).format(
            "DD MMM YYYY"
          )}
          \nCreated Datetime : ${moment(
            new Date(parseInt(this.invoice.createdAt))
          ).format("DD MMM YYYY, h:mm:ss A")}
          \nTotal Amount Due : ${this.invoice.total.toFixed(3)}

          `;
      QRCode.toDataURL(qrCodeString, function (err, url) {
        console.log(err);
        console.log(self.invoice);
        self.invoice.qrCode = url;
        printInvoice(self.invoice);
      });
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
  },
  data() {
    return {
      valid: true,
      imageUrl: "",
    };
  },
};
</script>
