<template>
  <v-app class="main">
    <app-main-navbar v-if="display"></app-main-navbar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import MainNavbar from "./core/components/MainNavbar.vue";
export default {
  components: {
    "app-main-navbar": MainNavbar,
  },
  computed: {
    display() {
      var isVisible = false;
      const currentPath = this.$route.path;
      if (currentPath !== "/") {
        this.$router.options.routes.forEach((element) => {
          if (currentPath == element.path) {
            isVisible = true;
          } else if (element.children) {
            element.children.forEach((child) => {
              if (currentPath === "/stock/" + child.path) {
                isVisible = true;
              }
            });
          }
        });
        if (!isVisible) {
          this.$router.replace({ path: "/" });
        }
      }
      return isVisible;
    },
  },
};
</script>

<style>
.main {
  background-color: #e5e5e5 !important;
}

.v-data-table-header {
  background-color: #5d7d9a;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #fff !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td > a {
  color: #004438 !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td > span > a {
  color: #004438 !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td > div > a {
  color: #004438 !important;
}

.v-data-table > .v-data-table__wrapper > table {
  background-color: #fff !important;
}

.custom-btn {
  border: 2px solid #f8166c;
}

.icon,
.icon-remove {
  height: 30px;
  width: 30px;
  /* left: 60px;
  margin-top: 120px; */
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./fonts/Gotham/GothamBook.ttf) format("swap");
}
</style>
