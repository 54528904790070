import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        // primary: "#EEDA84",
        primary: "#5D7D9A",
        header: "#004438",
        secondary: "#5D7D9A",
        background: "#FFFFFF",
        black: "#000000",
        bg: "#e5e5e5",
        // btncolor: "#004438",
        btncolor: "#5D7D9A",
        dasboard:"#fcfcfd"
      },
      light: {
        primary: "#5D7D9A",
        header: "#004438",
        secondary: "#5D7D9A",
        background: "#FFFFFF",
        black: "#000000",
        // greentext: "#004438",
        greentext: "#5D7D9A",
        bg: "#e5e5e5",
        // btncolor: "#004438",
        btncolor:"#5D7D9A",
        dasboard:"#fcfcfd"
      }
    }
  }
});
